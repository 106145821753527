import React from "react";
import OwlCarousel from "react-owl-carousel";
const Speakers = () => {
  const speakers = [
    {
      image: "./img/team/Picture1.png",
      name: "Gerardo Noto ",
      capacity: "Representative of the United Nations Development Program, Libya",
    },
    {
      image: "./img/team/Picture2.png",
      name: "Julien SCHMITT",
      capacity: "Chef of the party ,Private sector Development Program Libya",
    },
    {
      image: "./img/team/Picture3.png",
      name: "Amance BUSTREAU",
      capacity: "Desk officier, Gouvernance économique et financière EXPERTISE FRANCE",
    },
    {
      image: "./img/team/Picture4.png",
      name: "IR. TS. DR. MOHAMAD IKBAR BIN ABDUL WAHAB",
      capacity: "University of Kuala Lumpur",
    },

    {
      image: "./img/team/Picture5.png",
      name: "DR MUHAMMAD DATO (DR.) AZMI",
      capacity: "University of Kuala Lumpur",
    },
    {
      image: "./img/team/Picture6.png",
      name: "MOHAMED AHMED ALASWAD",
      capacity: " DEPUTY TEAM LEADER EXPERTISE FRANCE ",
    },
   
   
  ];
  return (
    <section
      id="speakers"
      className="container text-center my-5"
      style={{ direction: "ltr" }}
    >
      <h1 data-aos="fade-left" className="py-4 text-white">
        Confirmed Speakers
      </h1>
      <div className="card-speakers" data-aos="fade-up">
       <div className="row">
          {speakers.map((speaker) => (
            <div className="item col-md-4">
              <div className="team-info text-center">
                <img src={speaker.image} alt="" />
                <h4 className="item-info-title  text-center">{speaker.name}</h4>
                <p  className="text-center">{speaker.capacity}</p>
               
              </div>
            </div>
          ))}
          </div>
      </div>
    </section>
  );
};

export default Speakers;
