import React from "react";
import { footer } from "../sections";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF } from "@fortawesome/free-solid-svg-icons";

const Footer = ({ lang }) => {
  return (
    <>
      <div class="sections copyright py-4 text-center">
        <div class="container">
          <p class="copy-desc">
            &copy; {footer[lang]}
            {"  "}
            {new Date().getFullYear()}
         
          </p>
          <a target="_blank" style={{textDecoration : "none" ,cursor : "pointer" ,color : "rgb(13, 110, 148)"}} href="https://www.facebook.com/%D8%A7%D9%84%D9%85%D8%A4%D8%AA%D9%85%D8%B1-%D8%A7%D9%84%D8%AF%D9%88%D9%84%D9%8A-%D8%A7%D9%84%D9%85%D8%BA%D8%A7%D8%B1%D8%A8%D9%8A-%D8%A7%D9%84%D8%A3%D9%88%D9%84-%D9%84%D9%85%D8%B3%D8%AA%D8%AC%D8%AF%D8%A7%D8%AA-%D8%A7%D9%84%D8%AA%D9%86%D9%85%D9%8A%D8%A9-%D8%A7%D9%84%D9%85%D8%B3%D8%AA%D8%AF%D8%A7%D9%85%D8%A9-118792509965029/"> FaceBook </a>
        </div>
       
      </div>
    </>
  );
};

export default Footer;
