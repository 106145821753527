import React from "react";
import { s, navlink5 } from "../sections";
const Suports = ({ lang }) => {
  return (
    <section class="sections services py-5 " id="suports">
      <div class="container">
        <div class="section-header text-center text-white my-4">
          <h2 class="section-title">{navlink5[lang]}</h2>
          <div class="line">
            <span></span>
          </div>
        </div>

        <div class="row my-3">
          <div class="col-md-4 col-xs-12" data-aos="fade-dow">
            <div class="serv ">
            {/* eslint-disable-next-line */}
              <a href="https://npc.gov.ly/" target="_blank">
              <img src="./img/logo-npc.svg" alt="s" width="250" />
              </a>
            </div>
          </div>
          <div class="col-md-4 col-xs-12" data-aos="fade-dow">
            <div class="serv ">
            {/* eslint-disable-next-line */}
              <a href="https://www.planning.gov.ly/ar/" target="_blank">
              <img src="./img/planing.png" alt="s" width="250" />
              </a>
            </div>
          </div>
          <div class="col-md-4 col-xs-12" data-aos="fade-dow">
            <div class="serv ">
            {/* eslint-disable-next-line */}
              <a href="https://noc.ly/index.php/ar/" target="_blank">
              <img src="./img/petro.png" alt="s" width="250" />
              </a>
            </div>
          </div>
          <div class="col-md-4 col-xs-12" data-aos="fade-dow">
            <div class="serv ">
              {/* eslint-disable-next-line */}
              <a href="https://labour.gov.ly/" target="_blank">
              <img src="./img/work.png" alt="s" width="250" />
              </a>
            </div>
          </div>
          
          <div class="col-md-4 col-xs-12" data-aos="fade-dow">
            <div class="serv ">
               {/* eslint-disable-next-line */}
              <a href="http://www.fasrc.org/" target="_blank" >
              <img src="./img/comm.png" alt="" width="250" />
              </a>
            </div>
          </div>
          <div class="col-md-4 col-xs-12" data-aos="fade-dow">
            <div class="serv ">
              {/* eslint-disable-next-line */}
              <a href="https://www.lcrsss.ly/" target="_blank" >
              <img src="./img/comm1.png" alt="" width="250" />
              </a>
            </div>
          </div>
          <div class="col-md-4 col-xs-12" data-aos="fade-dow">
            <div class="serv ">
              {/* eslint-disable-next-line */}
              <a href="https://www.lcrsss.lyhttps://www.nesr.com/?fbclid=IwAR1KfSkPinRMohi4Ao9xlP9JbsBfiELVv_YWKQzlYGYkyfiTS3cCJLfd8DY/" target="_blank" >
              <img src="./img/nesr.png" alt="" width="250" />
              </a>
            </div>
          </div>
          <div class="col-md-4 col-xs-12" data-aos="fade-dow">
            <div class="serv ">
              {/* eslint-disable-next-line */}
              <a href="https://www.undp.org/" target="_blank" >
              <img src="./img/undp.png" alt="" width="250" />
              </a>
            </div>
          </div>
          <div class="col-md-4 col-xs-12" data-aos="fade-dow">
            <div class="serv ">
              {/* eslint-disable-next-line */}
              <a href="http://www.tourisme.gov.tn/ar/home.html" target="_blank" >
              <img src="./img/tt.png" alt="" width="250" />
              </a>
            </div>
          </div>
          <div class="col-md-4 col-xs-12" data-aos="fade-dow">
            <div class="serv ">
              {/* eslint-disable-next-line */}
              <a href="https://lamah.com/" target="_blank" >
              <img src="./img/amal.png" alt="" width="250" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Suports;
