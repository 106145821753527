import React, { Fragment, useEffect } from "react";
import Timer from "../timer/timer";
import { head1, head2, head3, head4, vedname, head5,btnTitle ,btnTitleG ,CountDown} from "../sections";
import Regester from "../modals/Regester";
import { connect } from "react-redux";
import VideoModals from "../modals/VideoModals";
import { getResearchs } from "../../actions/research";

const Header = ({ lang, researchs, getResearchs }) => {
  useEffect(() => {
    getResearchs();
  }, []);
 

  

  let URL = {en : "https://youtu.be/6_wx2WtKCIg" , ar : "https://youtu.be/q43LGZAP3II", fr : "https://youtu.be/6_wx2WtKCIg"}

  let URLG = {en : "https://youtu.be/8ig1Azo2RVc" , ar : "https://youtu.be/Kgy0CrwP_H8", fr : "https://youtu.be/8ig1Azo2RVc"}
  let researchsNumber =  608;
  // researchs.length !== 0 ? researchs.length +  10 : 0;
  return (
    <section className="showcase text-center" id="mains">
      <div className="video-container">
        <video src="/img/1.mp4" autoPlay muted loop />
      </div>
      <div className="content ">
        <h1>{head1[lang]}</h1>
        <h2 dangerouslySetInnerHTML={{ __html: head2[lang] }}></h2>
        <h3>
          <h4 className="my-3">{head5[lang]} </h4>
          <Timer lang={lang} time={"2021-3-13"} bgc={"rgba(62, 79, 155, 0.7)"} />
        </h3>
        {
          Date.now() < new Date("2020-12-16") && (<h3>
            <h4 className="my-3">{CountDown[lang]}</h4>
            <Timer lang={lang} time={"2020-12-16"} bgc={"rgba(150, 40, 27, 0.6)"} />
          </h3>)}
        
        <br />
        <h2>
          {" "}
          {head3[lang]}
          {"   "}
          <span> {  researchsNumber}</span>
          {"   "}
          
          {head4[lang]} {"   "} 246 {"   "}
        </h2>
       
        <br />
        {/* eslint-disable-next-line */}
        <a  target="_blank" href="https://docs.google.com/forms/d/e/1FAIpQLSfby56q21abOrKx7tCVS_y7MGwl5qfXA7jXZ03OROhjz-XDmg/viewform?fbclid=IwAR0gMXK4EREqI_xYA4FlfttVFqn49mxKs8MSDOk-5j2SehS5MX12-p-S-_0" className="btn btn-our mx-2 ">
        للتسجيل في المشروعات
        <span className="bg"></span>
      </a>
        
        {
          Date.now() < new Date("2021-1-1") && (
            <Fragment>
               <VideoModals 
               lang={lang} 
               URI={URLG} 
               videoname= {vedname}
               btnTitle = {btnTitleG[lang]}
              />
              <Regester lang={lang} />
              <VideoModals 
              lang={lang} 
              URI={URL}  
              videoname= {btnTitle}
              btnTitle = {btnTitle[lang]}
              />
              
            </Fragment>
          )
          // : (
          //   <a
          //     href="#list"
          //     className="modal-trigger   btn-large red darken-2 waves-effect waves-light btn "
          //   >
          //     {" "}
          //     <i class="material-icons left">format_list_numbered</i>
          //     {lang === "ar"
          //       ? " قائمة الملخصات المقبولة"
          //       : " accepted Abstructs list "}
          //   </a>
          // )
        }
      </div>
    </section>
  );
};
const mapStateToProps = (state) => ({
  researchs: state.research.researchs,
});
export default connect(mapStateToProps, { getResearchs })(Header);
